import { useState, useEffect, ChangeEvent } from 'react';

function CurrencyToggle() {
  // Step 1: Initialize state for currency
  const [currency, setCurrency] = useState('USD'); // Default to USD

  // Step 2: Retrieve the currency value from localStorage if available
  useEffect(() => {
    const storedCurrency = localStorage.getItem('pxlCurrency');
    if (storedCurrency) {
      setCurrency(storedCurrency);
    }
  }, []); // Empty dependency array to run this effect only once, during component initialization

  // Function to handle currency change
  const handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = event.target.value;

    // Update state and localStorage
    setCurrency(newCurrency);
    localStorage.setItem('pxlCurrency', newCurrency);

    window.location.reload();

    const metastarkAddress = localStorage.getItem('walletAddress');
    const metastarkStarkey = localStorage.getItem('walletStark');
    const metastarkNetwork = localStorage.getItem('walletNetwork');
    const metastarkEmail = localStorage.getItem('walletEmail');
    const metastarkProvider = localStorage.getItem('walletPreference');
    const metaCurrency = localStorage.getItem('pxlCurrency');
    const metaBalance = localStorage.getItem('pxlBalance');
    const metaLanguage = localStorage.getItem('pxlLanguage');

    window.postMessage(
      {
        type: 'UPDATE_WALLET_ADDRESS',
        walletAddress: metastarkAddress,
        walletNetwork: metastarkNetwork,
        walletStark: metastarkStarkey,
        walletEmail: metastarkEmail || '',
        walletPreference: metastarkProvider,
        pxlCurrency: metaCurrency,
        pxlLanguage: metaLanguage,
      },
      window.location.origin // Only send the message to the same origin
    );
  };

  return (
    <span>
      <select value={currency} onChange={handleCurrencyChange} className="currency-selector">
        <option value="USD">$ Dollar</option>
        <option value="EUR">€ Euro</option>
      </select>
    </span>
  );
}

export default CurrencyToggle;
